<template>
  <div>
    <portal to="subheader_actions">
      <b-button @click="openModal" class="mr-2">
        <b-icon icon="plus" aria-label="Help"></b-icon>
        Nuevo
      </b-button>
    </portal>
    <div class="row">
      <div class="col-md-12">
        <b-card header-tag="header" footer-tag="footer">
          <b-card-text>
            <div>
              <b-table
                head-variant="dark"
                :items="items"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
              >
                <template #cell(actions)="data">
                  <div class="h5 mb-0">
                    <b-button
                      size="sm"
                      variant="light"
                      v-b-modal.modal-form-user
                      @click="editItem(data)"
                    >
                      <b-icon icon="pencil-square"></b-icon>
                    </b-button>
                    <b-button
                      @click="removeItem(data)"
                      size="sm"
                      variant="light"
                    >
                      <b-icon icon="trash-fill"></b-icon>
                    </b-button>
                  </div>
                </template>
              </b-table>
            </div>
          </b-card-text>
          <template v-slot:footer>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </template>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      form: { is_active: 1 },
      loaded: false,
      showModal: false,
      currentObject: {},
      currentPage: 1,
      perPage: 12,
      items: [],
      fields: [
        {
          key: "actions",
          sortable: true,
          label: "Acciones"
        },
        {
          key: "id",
          label: "ID"
        },
        {
          key: "abbrev",
          sortable: true,
          label: "Abreviatura"
        },
        {
          key: "description",
          sortable: true,
          label: "Descripción"
        }
      ]
    };
  },
  computed: {
    rows() {
      return this.items.length;
    }
  },
  methods: {
    openToast({ message, object }) {
      this.$bvToast.toast(message, object);
    },
    editItem(data) {
      this.$router.push({
        path: `/human-resources/documents/${data.item.id}/editar`
      });
    },
    openModal() {
      this.$router.push({ name: "maintenances_document_signature" });
    },
    removeItem({ item: { id } }) {
      Swal.fire({
        title: "",
        text: "¿Está seguro de eliminar el documento?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonClass: "btn btn-secondary",
        confirmButtonText: "Sí, estoy seguro.",
        confirmButtonClass: "btn btn-secondary",
        cancelButtonText: "Cancelar"
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          ApiService.delete(`/api/document/${id}/`)
            .then(() => {
              this.openToast({
                message: "Se elimino correctamente el usuario",
                object: {
                  title: `Éxito`,
                  variant: "success",
                  solid: true
                }
              });
              this.items = this.items.filter(el => el.id !== id);
            })
            .catch(() => {
              this.openToast({
                message: "Ha ocurrido un error",
                object: {
                  title: `Error`,
                  variant: "danger",
                  solid: true
                }
              });
            });
        }
      });
    },
    listItems() {
      ApiService.get("api/document", "").then(response => {
        this.items = response.data;
      });
    },
    onCreated(object) {
      this.items.unshift(object);
    },
    onUpdated(object) {
      this.items = this.items.map(data => {
        const { id } = data;
        if (id === object.id) {
          return object;
        }
        return data;
      });
    }
  },
  created() {
    this.listItems();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Mantenimientos" },
      { title: "Tipos de documentos" }
    ]);
  },
  components: {}
};
</script>
